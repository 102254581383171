import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/ember-handlebars",
  "date": "2014-08-27",
  "title": "EMBER & HANDLEBARS",
  "author": "admin",
  "tags": ["development", "javascript", "ember.js"],
  "featuredImage": "feature.jpg",
  "excerpt": "Before getting into handlebars, let's look at how routing works in Ember.js. Router is solely responsible for all URLs in Ember."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Linking to Routes`}</h2>
    <p>{`Router is solely responsible for all URLs in Ember. Let's inspect the `}<inlineCode parentName="p">{`app.js`}</inlineCode>{` and `}<inlineCode parentName="p">{`index.html`}</inlineCode>{` files.`}</p>
    <p><strong parentName="p">{`app.js`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`App.router.map(function() {
    this.route('about');
});
`}</code></pre>
    <p><strong parentName="p">{`index.html`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<a href="#">Home</a>
<a href="#/about">About</a>
`}</code></pre>
    <p>{`It is considered a bad practice to do this. We don't hard code URL paths. Instead, we ask the routes for their path.`}</p>
    <h2>{`The Handlebars link-to helper`}</h2>
    <p>{`Use the route name to look up the path`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`{{#link-to 'index'}}Homepage{{/link-to}}
{{#link-to 'about'}}about{{/link-to}}
`}</code></pre>
    <p>{`The `}<strong parentName="p">{`link-to`}</strong>{` will determine the path based on the route name. The code above will generate the following HTML:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<a href="#/" class="ember-view" id="ember2">Homepage</a>
<a href="#/about" class="ember-view" id="ember3">About</a>
`}</code></pre>
    <h3>{`Extra Attributes`}</h3>
    <p>{`How to add a custom class to our link.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`{{#link-to 'index' class='nav-brand'}Home{{/link-to}}
`}</code></pre>
    <p>{`The above code will be rendered from template into`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<a href="#/" class="ember-view" id="ember2">Homepage</a>
`}</code></pre>
    <h3>{`tagName option`}</h3>
    <p>{`How to change the HTML element`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`{{#link-to 'index' tagName='li'}Home{{/link-to}}
{{#link-to 'about' tagName='li'}
About{{/link-to}}
`}</code></pre>
    <p>{`The above code will be rendered from template into`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<li class="ember-view" id="ember1">Home
<li class="ember-view" id="ember2">About
`}</code></pre>
    <p>{`Notice how href disappeared and now it is a list instead of a link. But the click still works as Ember adds click handlers.`}</p>
    <h2>{`Template Variables`}</h2>
    <p>{`You can use properties in your template, like the `}<strong parentName="p">{`productsCount`}</strong>{` below`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<script type='text/handlebars' data-template-name='index'>
    There are {{productsCount}} products        
</script>
`}</code></pre>
    <p>{`How do you set a property for use in a template?`}</p>
    <h2>{`Ember Controller`}</h2>
    <p>{`Ember COntroller provides properties for the template. Ember Controller is where a template looks to find a property value.
It decorates your applications data for the template and
contains information that is not saved to a server.`}</p>
    <p>{`Asking the Controller:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<script type='text/x-handlebars' data-template-name='index'>
    There are {{productsCount}} products
</script>
`}</code></pre>
    <p>{`Above code looks for a controller named `}<strong parentName="p">{`IndexController`}</strong>{`, and the property inside it.`}</p>
    <pre><code parentName="pre" {...{}}>{`App.IndexController = Ember.Controller.extend({
    productsCount: 5
});
`}</code></pre>
    <h2>{`Every Route has a Default Controller`}</h2>
    <p>{`Consider the code below: `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`App.Router.map(function() {
    this.route('about');
});
`}</code></pre>
    <p>{`Behind the scenes, Ember creates this About controller.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`App.AboutController = Ember.Controller.extend({ });
`}</code></pre>
    <p>{`If we want to declare properties that get rendered inside a template, then we can declare this Controller.`}</p>
    <h2>{`Binding with Metamorph`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<script type='text/x-handlebars' data-template-name='index'>
    There are {{productsCount}} products
</script>
`}</code></pre>
    <p>{`This will render as below:`}</p>
    <pre><code parentName="pre" {...{}}>{`There are
    <script id="metamorph-2-start" type="text/x-placeholder"></script>
    5
    <script id="metamorph-2-end" type="text/x-placeholder"></script>
    products

`}</code></pre>
    <p>{`Ember needs to wrap properties so it can
potentially update it later. (AKA. Binding)`}</p>
    <h2>{`How to Bind Attributes`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<script type='text/x-handlebars' data-template-name='index'>
    There are {{productsCount}} products
    <img {{bind-attr src='logo'}} alt='Logo' />
</script>
`}</code></pre>
    <p>{`In the rendered html, Ember meta data is still added to enable binding. (data-nindattr-1)`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<img src="/images/logo.png" data-bindattr-1="1">
`}</code></pre>
    <h2>{`Dynamic Content`}</h2>
    <p>{`How do we fetch the current time?`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<script type='text/x-handlebars' data-template-name='index'>
    Rendered on {{time}}
</script>
`}</code></pre>
    <p>{`We need to create a property that calls this function:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`(new Date()).toDateString()
`}</code></pre>
    <p>{`This is more then just a value, we need to
execute some code to get the value.`}</p>
    <h2>{`Controller Functions`}</h2>
    <p>{`We need to tell Ember this is a property and to call it`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`App.IndexController = Ember.Controller.extend({
    productsCount: 6,
    logo: '/images/logo.png',
    time: function() {
        return;
    }
});
`}</code></pre>
    <h2>{`Controller Functions as Properties`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<script type='text/x-handlebaaprps.'js data-template-name='index'>
Rendered on {{time}}
</script>
`}</code></pre>
    <p>{`time will be called, and that value used as a property`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`App.IndexController = Ember.Controller.extend({
    productsCount: 6,
    logo: '/images/logo.png',
    time: function() {
        return
    }
});
`}</code></pre>
    <h2>{`Linking to Routes`}</h2>
    <p>{`Router is solely responsible for all URLs in Ember.`}</p>
    <p><strong parentName="p">{`app.js`}</strong></p>
    <pre><code parentName="pre" {...{}}>{`App.router.map(function() {
    this.route('about');
});
`}</code></pre>
    <p><strong parentName="p">{`index.html`}</strong></p>
    <pre><code parentName="pre" {...{}}>{`<a href="#">Home</a>
<a href="#/about">About</a>
`}</code></pre>
    <p>{`It is considered a bad practice to do this. We don't hard code URL paths. Instead, we ask the routes for their path.`}</p>
    <h2>{`The Handlebars link-to helper`}</h2>
    <p>{`Use the route name to look up the path`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`{{#link-to 'index'}}Homepage{{/link-to}}
{{#link-to 'about'}}about{{/link-to}}
`}</code></pre>
    <p>{`The `}<strong parentName="p">{`link-to`}</strong>{` will determine the path based on the route name. The code above will generate the following HTML:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<a href="#/" class="ember-view" id="ember2">Homepage</a>
<a href="#/about" class="ember-view" id="ember3">About</a>
`}</code></pre>
    <h3>{`Extra Attributes`}</h3>
    <p>{`How to add a custom class to our link.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`{{#link-to 'index' class='nav-brand'}Home{{/link-to}}
`}</code></pre>
    <p>{`The above code will be rendered from template into`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<a href="#/" class="ember-view" id="ember2">Homepage</a>
`}</code></pre>
    <h3>{`tagName option`}</h3>
    <p>{`How to change the HTML element`}</p>
    <p>{`\``}<inlineCode parentName="p">{`html`}</inlineCode>{`
{{#link-to 'index' tagName='li'}Home{{/link-to}}
{{#link-to 'about' tagName='li'}
About{{/link-to}}`}</p>
    <pre><code parentName="pre" {...{}}>{`
The above code will be rendered from template into

\`\`\`html
<li class="ember-view" id="ember1">Home
<li class="ember-view" id="ember2">About
`}</code></pre>
    <p>{`Notice how href disappeared and now it is a list instead of a link. But the click still works as Ember adds click handlers.`}</p>
    <h2>{`Template Variables`}</h2>
    <p>{`You can use properties in your template, like the `}<strong parentName="p">{`productsCount`}</strong>{` below`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<script type='text/handlebars' data-template-name='index'>
    There are {{productsCount}} products        
</script>
`}</code></pre>
    <p>{`How do you set a property for use in a template?`}</p>
    <h2>{`Ember Controller`}</h2>
    <p>{`Ember COntroller provides properties for the template. Ember Controller is where a template looks to find a property value.
It decorates your applications data for the template and contains information that is not saved to a server.`}</p>
    <p>{`Asking the Controller:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<script type='text/x-handlebars' data-template-name='index'>
    There are {{productsCount}} products
</script>
`}</code></pre>
    <p>{`Above code looks for a controller named `}<strong parentName="p">{`IndexController`}</strong>{`, and the property inside it.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`App.IndexController = Ember.Controller.extend({
    productsCount: 5
});
`}</code></pre>
    <h2>{`Every Route has a Default Controller`}</h2>
    <p>{`Consider the code below: `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`App.Router.map(function() {
    this.route('about');
});
`}</code></pre>
    <p>{`Behind the scenes, Ember creates this About controller.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`App.AboutController = Ember.Controller.extend({ });
`}</code></pre>
    <p>{`If we want to declare properties that get rendered inside a
template, then we can declare this Controller.`}</p>
    <h2>{`Binding with Metamorph`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<script type='text/x-handlebars' data-template-name='index'>
    There are {{productsCount}} products
</script>
`}</code></pre>
    <p>{`This will render as below:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`There are
    <script id="metamorph-2-start" type="text/x-placeholder"></script>
    5
    <script id="metamorph-2-end" type="text/x-placeholder"></script>
    products
`}</code></pre>
    <p>{`Ember needs to wrap properties so it can potentially update it later. (AKA. Binding)`}</p>
    <h2>{`How to Bind Attributes`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<script type='text/x-handlebars' data-template-name='index'>
    There are {{productsCount}} products
    <img {{bind-attr src='logo'}} alt='Logo' />
</script>
`}</code></pre>
    <p>{`In the rendered html, Ember meta data is still added to enable binding. (data-nindattr-1)`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<img src="/images/logo.png" data-bindattr-1="1">
`}</code></pre>
    <h2>{`Dynamic Content`}</h2>
    <p>{`How do we fetch the current time?`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<script type='text/x-handlebars' data-template-name='index'>
    Rendered on {{time}}
</script>
`}</code></pre>
    <p>{`We need to create a property that calls this function:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`(new Date()).toDateString()
`}</code></pre>
    <p>{`This is more then just a value, we need to execute some code to get the value.`}</p>
    <h2>{`Controller Functions`}</h2>
    <p>{`We need to tell Ember this is a property and to call it`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`App.IndexController = Ember.Controller.extend({
    productsCount: 6,
    logo: '/images/logo.png',
    time: function() {
        return;
    }
});
`}</code></pre>
    <h2>{`Controller Functions as Properties`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<script type='text/x-handlebaaprps.'js data-template-name='index'>
    Rendered on {{time}}
</script>
`}</code></pre>
    <p>{`time will be called, and that value used as a property`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`App.IndexController = Ember.Controller.extend({
    productsCount: 6,
    logo: '/images/logo.png',
    time: function() {
        return
    }
});
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      